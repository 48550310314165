.navbar-ruler {
    bottom: 0;
    top: 2rem;

    &::before {
        background-color: $grey-light;
        content: '';
        display: block;
        height: 3px;
        left: -1px;
        position: absolute;
        top: 54px;
        width: 3px;
    }
}

.ruler {
    bottom: 2rem;
    top: 0;
}

.ruler,
.navbar-ruler {
    background-color: $grey-light;
    content: '';
    display: block;
    position: absolute;
    width: 1px;

    @media screen and (max-width: $tablet) {
        display: none;
    }

    @media screen and (min-width: $desktop) {
        left: calc(50% + #{$desktop / 2 - $gap} - #{$navbar-container-padding});
    }

    @media screen and (min-width: $widescreen) {
        left: calc(50% + #{$widescreen / 2 - $gap} - #{$navbar-container-padding});
    }

    @media screen and (min-width: $fullhd) {
        left: calc(50% + #{$fullhd / 2 - $gap} - #{$navbar-container-padding});
    }
}
