$index-image-margin: 8px;

.index.container {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    position: relative;
    z-index: 2;

    .discover-more-container {
        justify-content: flex-end;
    }

    .discover-more {
        margin: 1rem 5rem;
        position: relative;

        a:hover {
            color: $primary;
            text-decoration: underline;
        }

        &:after,
        &:before {
            background-color: $primary;
            border-radius: 1em;
            display: block;
            content: '';
            height: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            right: -2rem;
            width: 1rem;
        }

        &:before {
            left: -42vw;
            width: 40vw;
        }
    }

    @media (max-width: $desktop - 1px) {
        flex-direction: column;
    }
}

.index .image-col {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $desktop) {
        margin-right: $index-image-margin;
    }

    &:last-child {
        margin-right: 0;
    }

    img {
        display: block;
    }

    .img-container {
        margin-bottom: 8px;
        overflow: hidden;
        position: relative;

        &:hover .img-description {
            transform: translateY(0);
        }
    }

    .img-description {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0.5rem;
        text-align: center;
        transition: transform 0.25s ease-in-out;
        transform: translateY(100%);
    }

    .image-row {
        display: flex;
        flex-direction: row;

        .img-container + .img-container {
            margin-left: $index-image-margin;
        }
    }

    .has-margin {
        margin-left: $index-image-margin;
    }
}

.index {
    .image-container {
        flex-direction: column;
        margin: auto;

        @media (min-width: $desktop) {
            flex-direction: row;
        }
    }

    @media (min-width: $desktop) {
        $index-image-width: 113px;

        .img-one-col {
            width: $index-image-width;
        }

        .img-two-col {
            width: $index-image-width * 2 + $index-image-margin;
        }
    }

    @media (min-width: $widescreen) {
        $index-image-width: 137px;

        .img-one-col {
            width: $index-image-width;
        }

        .img-two-col {
            width: $index-image-width * 2 + $index-image-margin;
        }
    }

    @media (min-width: $fullhd) {
        $index-image-width: 161px;

        .img-one-col {
            width: $index-image-width;
        }

        .img-two-col {
            width: $index-image-width * 2 + $index-image-margin;
        }
    }
}
