@import 'bulma/sass/utilities/initial-variables';

@import 'bulma/sass/utilities/functions';

// Colors
$primary: #00c0f3;
$link: $primary;

// Body
$body-background-color: $white-ter;
$body-color: $black-ter;
$family-sans-serif: 'Eras', sans-serif;
$family-serif: 'Bernadette', serif;

// Navbar
$navbar-height: 7rem;
$navbar-item-max-height: 3rem;
$navbar-item-img-max-height: 3rem;
$navbar-dropdown-item-active-background-color: $link;
$navbar-divider-background-color: $link;
$navbar-container-padding: 2rem;
$navbar-menu-padding: 2rem;

// Home
$hero-img-padding: 6px;
$hero-height-desktop: 250px + $hero-img-padding;
$hero-height-widescreen: 300px + $hero-img-padding;
$hero-height-fullhd: 350px + $hero-img-padding;
