.navbar {
    @media screen and (max-width: $desktop) {
        min-height: $navbar-height / 2;
    }

    @media screen and (min-width: $desktop) {
        & > .container {
            padding: 0 $navbar-container-padding;
        }
    }
}

.navbar-brand img:first-child {
    margin-right: 2rem;
}

.navbar-burger {
    border: none;

    .navbar & span {
        height: 2px;
        left: calc(50% - 10px);
        width: 20px;

        &:first-child {
            top: calc(50% - 8px);
        }

        &:last-child {
            top: calc(50% + 6px);
        }
    }

    .navbar &.is-active span {
        &:first-child {
            transform: translateY(7px) rotate(45deg);
        }
        &:last-child {
            transform: translateY(-7px) rotate(-45deg);
        }
    }
}

.navbar-start,
.navbar-end {
    align-items: center;
    position: relative;
}

.navbar-end {
    padding: 2rem 0;
}

.navbar-menu {
    font-size: 0.85rem;
    padding-right: $navbar-menu-padding;

    @media screen and (min-width: $desktop) {
        .navbar-item {
            background-color: $white;
            border-bottom: 1px solid $grey-light;
            color: $grey-light;
            height: $navbar-height / 2;
            position: relative;
            text-transform: uppercase;

            &.is-active {
                color: $black;
            }

            &:last-child::after {
                content: '';
                display: block;
                width: $navbar-item-max-height + $navbar-menu-padding;
                position: absolute;
                right: -($navbar-item-max-height + $navbar-menu-padding);
                height: 1px;
                background-color: $grey-light;
                bottom: -1px;
            }
        }
    }
}

#burger-toggle {
    display: none;
}

#burger-toggle:checked ~ .navbar-menu {
    display: block;
}

.js .navbar-item-highlight {
    height: 3px;
    position: absolute;
    left: 0;
    background-color: $primary;
    top: $navbar-height * 3 / 4;
    transition: left 250ms linear;
    z-index: 1;

    @media screen and (max-width: $desktop) {
        & {
            display: none;
        }
    }
}

.language-menu {
    padding-right: 2 * $navbar-menu-padding;

    a {
        color: $grey;
        margin: 0 0.5rem;
        text-decoration: underline;
        text-transform: uppercase;
    }
}
