html.js {
    opacity: 1;
    transition: opacity 250ms;

    &.wf-loading {
        opacity: 0;
    }
}

.has-text-serif {
    font-family: $family-serif;
}

.has-text-underlined {
    text-decoration: underline;
}

.spacer {
    margin: 0 1rem 0 0.5rem;
}
