@import './variables';

@import 'bulma';

@import 'photoswipe/dist/photoswipe.css';

@import 'photoswipe/dist/default-skin/default-skin.css';

@import './navbar';

@import './typography';

@import './about';

@import './addons';

@import './contacts';

@import './gallery';

@import './index';

@import './portfolio';

@import './work';

html,
body {
    display: flex;
    min-height: 100%;
}

body {
    flex: 1;
    flex-direction: column;
    font-size: 15px;
    position: relative;
}

main {
    display: flex;
    flex: 1;

    @media screen and (min-width: $desktop) {
        & > .container {
            padding: 0 5rem;
        }
    }
}

blockquote {
    line-height: 1.2;
    margin: 2rem 0;
}

p {
    margin: 1rem 0;
}

[hidden] {
    display: none;
}

.is-divider {
    background-color: $grey;
    margin: 2em 0;
    width: 1px;
}

.is-full-width {
    width: 100%;
}

.is-horizontal-padded {
    padding: $section-padding;
    padding-bottom: 0;
    padding-top: 0;
}

.is-vertical-center {
    display: flex;
    align-items: center;
}

.footer {
    padding: 1.5rem;

    p {
        margin: 0.25rem 0;
    }
}
