.contacts {

    .icon-with-label {

        margin-bottom: 0.5em;

        img {
            margin-right: 0.5em;
            vertical-align: middle;
            width: 24px;
        }

        &:last-child {
            margin-top: 1em;
        }
    }
}

#contact {

    & + #thanks {
        display: none;
    }

    &:target {
        display:none;

        & + #thanks {
            display: block;
        }
    }
}
