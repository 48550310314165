.portfolio-gallery {
    width: 100%;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}

.gallery-image-container {
    height: 200px;
    margin: 25px;
    overflow: hidden;
    position: relative;
    width: 200px;

    img {
        filter: grayscale(100%);
        transition: filter 250ms linear;
    }
    &:hover img {
        filter: none;
    }

    .description {
        background-color: rgba(0, 0, 0, 0.5);
        color: $white;
        bottom: 0;
        padding: 0.5rem;
        position: absolute;
        text-align: center;
        transform: translateY(100%);
        transition: transform 0.25s ease-in-out;
        width: 100%;
    }
    &:hover .description {
        transform: translateY(0);
    }
}

.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
    background-image: url('images/photoswipe/default-skin.png');
}

.pswp__button.pswp__button--arrow--left,
.pswp__button.pswp__button--arrow--right {
    background-image: none;
}

.pswp__bottom-bar {
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;

    .pswp__caption {
        color: #ccc;
        font-size: 13px;
        line-height: 20px;
        padding: 10px;
        position: static;
        text-align: center;
    }
}

.pswp__thumbnails {
    display: flex;
    margin: auto;
    max-width: 100%;
    overflow-x: auto;

    img {
        height: 48px;
        margin: 8px;

        &.active {
            box-shadow: 0 0 7px 3px $primary;
        }
    }
}
