$list-book-size: 16px;

.work {
    .offer-list h3 {
        margin: 2rem 0;
    }

    li {
        line-height: 2em;
        padding-left: $list-book-size * 1.5;
        position: relative;

        &::before {
            background-image: url('/images/book-16.png');
            background-repeat: no-repeat;
            content: '';
            display: block;
            height: $list-book-size;
            left: 0;
            position: absolute;
            width: $list-book-size;
            top: 0.5em;
        }
    }
}
