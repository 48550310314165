.about {
    .profile-img {
        @media (max-width: $tablet) {
            display: block;
            margin: 0 auto;
            width: 278px;
        }
    }

    .cv {
        margin: 1em 0;

        ul {
            list-style: disc;
            padding-left: 2em;
        }

        a {
            text-decoration: underline;
        }
    }
}
