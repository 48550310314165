.portfolio {
    align-items: center;
    height: 100%;
}

.portfolio-hero {
    display: block;
    padding: 2rem;
    position: relative;

    img {
        display: block;
    }

    .overlay {
        background-color: $white;
        border: 1rem solid $primary;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.25s ease-in-out;
        z-index: 1;
    }

    &:hover {
        color: $primary;

        .overlay {
            opacity: 0.666;
        }

        .caption {
            color: $primary;
        }
    }

    .caption {
        color: $grey-dark;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;
    }
}

.portfolio-gallery {
    .projects {
        margin-bottom: 3em;

        ul {
            list-style: disc;
        }

        li {
            margin: 1em 2em;
        }

        .project-title {
            margin-right: 0.333em;
        }
        .project-agency {
            margin-left: 0.333em;
        }
    }

    .filter {
        color: $grey;

        &:hover,
        &.active {
            color: $primary;
            text-decoration: underline;
        }
    }
}
